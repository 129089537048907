import { useAuthentication } from "../../keycloak"
import { useEffect, useState } from "react"
import { toaster } from "baseui/toast"
import { Button } from "baseui/button"
import { useRouter } from "next/router"

type AuthTimerProps = {
  initialSeconds: number
}

export function AuthTimer({ initialSeconds }: AuthTimerProps) {
  const router = useRouter()
  const { logout } = useAuthentication()
  const [remainingSeconds, setRemainingSeconds] = useState(initialSeconds)
  useEffect(() => {
    setRemainingSeconds(initialSeconds)
  }, [router.asPath, initialSeconds])
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingSeconds((sec) => sec - 1)
    }, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])
  useEffect(() => {
    if (remainingSeconds <= 0) {
      logout()
    }
    if (remainingSeconds === 30) {
      toaster.negative(<>자동 로그아웃까지 30초 남았습니다.</>)
    }
    if (remainingSeconds === 60) {
      toaster.negative(<>자동 로그아웃까지 1분 남았습니다.</>)
    }
    if (remainingSeconds === 120) {
      toaster.negative(<>자동 로그아웃까지 2분 남았습니다.</>)
    }
    if (remainingSeconds === 180) {
      toaster.negative(<>자동 로그아웃까지 3분 남았습니다.</>)
    }
  }, [remainingSeconds, logout])
  const min = Math.floor(remainingSeconds / 60)
  const sec = remainingSeconds - min * 60

  return (
    <div className="text-center text-xs">
      {remainingSeconds <= 0 ? (
        <span className="font-bold text-red-500">00:00</span>
      ) : (
        <span
          className={remainingSeconds <= 180 ? "font-bold text-red-500" : ""}
        >
          {min.toString().padStart(2, "0")}:{sec.toString().padStart(2, "0")}
        </span>
      )}
      <Button
        disabled={remainingSeconds <= 0}
        onClick={() => setRemainingSeconds(initialSeconds)}
        size="mini"
        className="ml-2 inline-block px-1 py-0.5"
        kind="secondary"
      >
        로그인 연장
      </Button>
    </div>
  )
}
