import { ReactNode } from "react"
import { ROLE } from "../../utils"
import { Organization } from "@today/api/tracker"

type NavigationSection = {
  title: ReactNode
  items: {
    title: ReactNode
    url?: string
    requiredRole?: string
    externalLink?: boolean
    badge?: boolean
    activeOnSubPath?: boolean
  }[]
  // 해당 섹션을 표시할 수 있는 권한. 여러 개 중 하나라도 만족하면 표시한다.
  allowedRoles?: string[]
  isCollapsible?: boolean
}

export function getNavigationSections(roles: string[]): NavigationSection[] {
  const sections: NavigationSection[] = [
    {
      title: "시뮬레이션 결과",
      items: [
        {
          title: "일 1.5만개",
          url: "/simulation/15000",
          requiredRole: "translink",
        },
        {
          title: "일 5만개",
          url: "/simulation/50000",
          requiredRole: "translink",
        },
        {
          title: "일 20만개",
          url: "/simulation/200000",
          requiredRole: "translink",
        },
        {
          title: "일 50만개",
          url: "/simulation/500000",
          requiredRole: "translink",
        },
      ],
      allowedRoles: ["translink"],
    },
    {
      title: "설정",
      items: [
        {
          title: "로그아웃",
          url: "/logout",
        },
      ],
    },
  ]
  return sections
    .filter((section) => {
      const { allowedRoles } = section
      return !(
        allowedRoles && !roles.find((role) => allowedRoles.includes(role))
      )
    })
    .map((section) => ({
      ...section,
      items: section.items.filter(
        ({ requiredRole }) => !requiredRole || roles.includes(requiredRole)
      ),
    }))
}
