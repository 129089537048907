import { AuthTimer } from "@today/auth/components/timer/AuthTimer"
import { classNames } from "@today/lib"
import { useStyletron } from "baseui"
import { Menu } from "baseui/icon"
import Link from "next/link"
import { PropsWithChildren, useEffect } from "react"
import { useRecoilState } from "recoil"
import { ENV } from "../../config"
import { showsSideNavigationState } from "../../state/showsSideNavigationState"

export function TopNavigation() {
  const [css] = useStyletron()
  const [showsSideNavigation, setShowsSideNavigation] = useRecoilState(
    showsSideNavigationState
  )
  useEffect(() => {
    setShowsSideNavigation(window.screen.width > 1024)
  }, [setShowsSideNavigation])

  return (
    <div className="bg-white">
      {ENV !== "prod" && (
        <AttentionRibbon className="bg-red-500">
          {ENV.toUpperCase()} 환경입니다.&nbsp;
          <a className="underline" href="https://portal.vtov.studio">
            운영 환경 바로가기
          </a>
        </AttentionRibbon>
      )}
      <div className="relative w-full">
        <div className="flex items-center border-b border-gray-200 px-4 py-4">
          <button onClick={() => setShowsSideNavigation(!showsSideNavigation)}>
            <Menu size={24} />
          </button>
          <div className="ml-4 text-2xl font-semibold">
            <Link href="/" passHref>
              <a>
                <span className="hidden sm:inline">To-Day </span>Simulator
              </a>
            </Link>
          </div>
          <div className="flex-1" />
          <AuthTimer initialSeconds={1800} />
        </div>
        <div className="absolute right-0 top-0">
          <div
            className={css({
              [`@media screen and (max-width: 1549px)`]: {
                marginTop: "0.8rem",
                marginRight: "0.25rem",
                maxWidth: "80px",
              },
              [`@media screen and (min-width: 1550px)`]: {
                marginTop: "1.25rem",
                marginRight: "1.25rem",
              },
            })}
          ></div>
        </div>
      </div>
    </div>
  )
}
function AttentionRibbon({
  className,
  children,
}: PropsWithChildren<{
  className: string
}>) {
  return (
    <div
      className={classNames(
        "py-1 text-center text-sm font-semibold text-white",
        className
      )}
    >
      {children}
    </div>
  )
}
