import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react"
import { useInterval, useDebounceValue } from "usehooks-ts"
import styled from "styled-components"
import { Direction, Slider, SliderProps } from "react-player-controls"
import { FaPlay, FaPause } from "react-icons/fa"

export type TimeControlOption = {
  startTime: string
  stepMinutes: number
  count: number
}

const TimeContext = React.createContext<{
  time: null | string
  setTime: (time: string) => void
}>({
  time: null,
  setTime: (time: string) => {},
})

const TimeControlOptionContext = React.createContext<{
  option: TimeControlOption
  setOption: (option: TimeControlOption) => void
}>({
  option: {
    startTime: "00:00",
    stepMinutes: 10,
    count: 1,
  },
  setOption: (option: TimeControlOption) => {},
})

const TimeControlProvider: React.FC = ({ children }) => {
  const [time, setTime] = useState<null | string>(null)
  const [option, setOption] = useState({
    startTime: "00:00",
    stepMinutes: 10,
    count: 1,
  })
  return (
    <TimeContext.Provider value={{ time, setTime }}>
      <TimeControlOptionContext.Provider value={{ option, setOption }}>
        {children}
      </TimeControlOptionContext.Provider>
    </TimeContext.Provider>
  )
}

export { TimeControlProvider }

export const useTime = () => {
  const { time, setTime } = useContext(TimeContext)
  const { option } = useContext(TimeControlOptionContext)
  return {
    time: time ?? option.startTime,
    setTime,
  }
}

export const useTimeOption = () => {
  return {
    setTimeControlOption: useContext(TimeControlOptionContext).setOption,
  }
}

const WHITE_SMOKE = "#eee"
const GRAY = "#878c88"
const GREEN = "#72d687"

type SliderBarProps = {
  direction: Direction
  value: number
  style: React.CSSProperties
}

const SliderBar: React.FC<SliderBarProps> = ({ direction, value, style }) => (
  <div
    style={{
      position: "absolute",
      background: GRAY,
      borderRadius: 4,
      top: 0,
      bottom: 0,
      left: 0,
      width: `${value * 100}%`,
      ...style,
    }}
  />
)

type SliderHandleProps = {
  direction: Direction
  value: number
  style: React.CSSProperties
}

const SliderHandle: React.FC<SliderHandleProps> = ({
  direction,
  value,
  style,
}) => (
  <div
    style={{
      position: "absolute",
      width: 16,
      height: 16,
      background: GREEN,
      borderRadius: "100%",
      transform: "scale(1)",
      transition: "transform 0.2s",
      top: 0,
      left: `${value * 100}%`,
      marginTop: -4,
      marginLeft: -8,
      ...style,
    }}
  />
)

type ProgressBarProps = Omit<SliderProps, "style"> & {
  direction: Direction
  value: number
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  direction,
  value,
  onChange,
  ...props
}) => (
  <Slider
    direction={direction}
    onChange={onChange}
    style={{
      width: "100%",
      height: 8,
      borderRadius: 4,
      background: WHITE_SMOKE,
      transition: "width 0.1s",
      cursor: "pointer",
    }}
    {...props}
  >
    <SliderBar
      direction={direction}
      value={value}
      style={{ background: GREEN }}
    />
    <SliderHandle
      direction={direction}
      value={value}
      style={{ background: GREEN }}
    />
  </Slider>
)

const Container = styled.div`
  height: 35px;
  background-color: #333;
  z-index: 999;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
  color: white;

  & > *:not(:first-child) {
    margin-left: 15px;
  }

  .time-controller-icon {
    cursor: pointer;
  }

  .time-controller-value {
    width: 35px;
  }
`

function toValue(option: TimeControlOption, time: null | string): number {
  const [baseHour, baseMin] = option.startTime
    .split(":")
    .map((e) => parseInt(e))
  const [hour, min] = (time ?? option.startTime)
    .split(":")
    .map((e) => parseInt(e))
  const base = baseHour * 60 + baseMin
  const max = base + option.stepMinutes * option.count
  const current = hour * 60 + min
  let value: number
  if (base <= current) {
    value = (current - base) / (max - base)
  } else {
    value = (24 * 60 + current - base) / (max - base)
  }
  return value
}

function toTime(option: TimeControlOption, value: number): string {
  const [baseHour, baseMin] = option.startTime
    .split(":")
    .map((e) => parseInt(e))
  const elapsedMins = Math.round(value * option.count) * option.stepMinutes
  const newMin = (baseMin + elapsedMins) % 60
  const newHour = baseHour + parseInt(`${(baseMin + elapsedMins) / 60}`)
  return `${newHour < 10 ? "0" : ""}${newHour}:${
    newMin < 10 ? "0" : ""
  }${newMin}`
}

export const TimeController: React.FC = () => {
  const { time, setTime } = useContext(TimeContext)
  const { option } = useContext(TimeControlOptionContext)
  const [localTime, setLocalTime] = useState(time)

  const nullTimeRef = useRef(time === null)
  useEffect(() => {
    if (nullTimeRef.current && time !== null) {
      setLocalTime(time)
      nullTimeRef.current = false
    }
  }, [time])

  const [debouncedLocalTime] = useDebounceValue<null | string>(localTime, 500)
  useEffect(() => {
    if (debouncedLocalTime!) {
      setTime(debouncedLocalTime)
    }
  }, [debouncedLocalTime, setTime, option])

  const value = toValue(option, time)
  const [isPause, setPause] = useState<boolean>(true)
  const updateValue = useCallback(
    (newVal: number) => {
      setLocalTime(toTime(option, newVal))
    },
    [option]
  )
  useInterval(() => {
    if (isPause) {
      return
    }
    updateValue(Math.min(1, value + 1 / option.count))
  }, 2000)
  return (
    <Container className="time-controller">
      {isPause ? (
        <span className="time-controller-icon" onClick={() => setPause(false)}>
          <FaPlay />
        </span>
      ) : (
        <span className="time-controller-icon" onClick={() => setPause(true)}>
          <FaPause />
        </span>
      )}
      <span className="time-controller-value mr-2">
        {localTime ?? option.startTime}
      </span>
      <ProgressBar
        direction={Direction.HORIZONTAL}
        value={toValue(option, localTime)}
        onChange={updateValue}
      />
    </Container>
  )
}
