import axios, { AxiosInstance } from "axios"
import applyCaseMiddleware from "axios-case-converter"
import { LoadStatusDetailEvent, TraceEvent } from "./types"

export class Tracer {
  client: AxiosInstance

  constructor(baseUrl: string, token: string) {
    this.client = applyCaseMiddleware(
      axios.create({
        baseURL: baseUrl,
        headers: {
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      })
    )
  }

  async listLoadStatuses(
    invoiceNumber: string,
    type: "id" | "invoice_number" = "id"
  ): Promise<LoadStatusDetailEvent[]> {
    const { data } = await this.client.get(
      `/trace/v1/loads/${invoiceNumber}/statuses?type=${type}`
    )
    return data.statuses as LoadStatusDetailEvent[]
  }

  async listLoadTraces(invoiceNumber: string): Promise<TraceEvent[]> {
    const { data } = await this.client.get(
      `/trace/v1/loads/${invoiceNumber}/traces?type=invoice_number`
    )
    return data.traces as TraceEvent[]
  }
}
