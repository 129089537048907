import { useAuthentication, useUserInfo } from "@today/auth"
import { Accordion, Panel } from "baseui/accordion"
import { Item as BaseItem, Navigation } from "baseui/side-navigation"
import { useRouter } from "next/router"
import { Fragment, ReactNode, useMemo } from "react"
import { FiExternalLink } from "react-icons/fi"
import { getNavigationSections } from "./navigation-items"
import { classNames } from "@today/lib"

export function SideNavigation() {
  const showsSideNavigation = true
  const { clientRoles, outsourcingOrganizationId } = useUserInfo()
  const sections = useMemo(() => {
    return getNavigationSections(clientRoles)
  }, [clientRoles])
  return (
    <div
      className={classNames(
        "flex h-full w-56 flex-col overflow-y-scroll border-r pl-2 pt-2",
        showsSideNavigation ? "block" : "hidden"
      )}
    >
      {sections.map((section, i) => {
        const { title, items, isCollapsible } = section
        const sectionNode = (
          <Section
            key={i}
            items={items.map(
              ({ title, url, externalLink, badge, activeOnSubPath }) => ({
                title: externalLink ? (
                  <div className="flex items-center gap-x-2">
                    {title}
                    <FiExternalLink />
                  </div>
                ) : badge ? (
                  <div className="relative inline-block">
                    {title}
                    <div className="absolute top-[1px] right-[-10px] h-[6px] w-[6px] rounded-[50%] bg-red-500" />
                  </div>
                ) : (
                  title
                ),
                itemId: url,
                activeOnSubPath,
              })
            )}
          />
        )
        if (isCollapsible) {
          return (
            <Collapsible key={i} title={title}>
              {sectionNode}
            </Collapsible>
          )
        } else {
          return (
            <Fragment key={i}>
              <Title>{title}</Title>
              {sectionNode}
            </Fragment>
          )
        }
      })}
    </div>
  )
}

function Title(props: { children: ReactNode }) {
  return <div className="px-5 py-4 font-semibold">{props.children}</div>
}

type Item = BaseItem & {
  activeOnSubPath?: boolean
}

function Section(props: { items: Item[] }) {
  const router = useRouter()
  const { logout } = useAuthentication()
  return (
    <Navigation
      items={props.items}
      activeItemId={router.pathname}
      activePredicate={(item, activeItemId) =>
        (item as Item).activeOnSubPath
          ? item.itemId
            ? activeItemId.startsWith(item.itemId)
            : false
          : router.asPath.replace(/#.+$/g, "") ===
            (item.itemId as string).replace(/#.+$/g, "")
      }
      onChange={({ event, item }) => {
        event.preventDefault()
        if (item.itemId === "/logout") {
          logout()
        } else if (item.itemId) {
          router.push(item.itemId)
        }
      }}
    />
  )
}

function Collapsible(props: { title: ReactNode; children: ReactNode }) {
  return (
    <Accordion>
      <Panel
        title={props.title}
        overrides={{
          Header: {
            style: {
              fontWeight: 600,
            },
          },
          PanelContainer: {
            style: {
              borderBottomWidth: 0,
            },
          },
          Content: {
            style: {
              paddingTop: 0,
              paddingBottom: "10px",
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        }}
      >
        {props.children}
      </Panel>
    </Accordion>
  )
}
